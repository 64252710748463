    <!-- 成为合伙人-->

<template>
    <div class="Partnerv">
        <div class="banner-box">
            <div class="content title-text-box">
                <h1>勇往「值钱」</h1>
                <h1>寻找好生活合伙人</h1>
                <p>你是否在寻找一份可以奋斗终身的事业？</p>
                <p>你是否为发展瓶颈而苦恼？</p>
                <p>你是否还在寻找一个靠谱的大平台，提升收益？</p>
            </div>
        </div>
        <div class="line-box">
            <div class="content list-text-box pad-t-120">
                <h1>合伙人晋升体系</h1>
                <span>{{ "Partnership Promotion System".toUpperCase() }}</span>
            </div>
        </div>
        <div class="list-text-box content mar-t-80">
            <h1>招聘对象及要求</h1>
            <span>{{ "Recruitment target requirements".toUpperCase() }}</span>
        </div>
        <div class="list-box content-p flex flwr-wr">
            <div
                class="flex"
                :class="'bg' + (i + 1)"
                v-for="(item, i) in worksList"
                :key="i"
            >
                <img
                    :src="
                        require('@/assets/img/partnerv/' + item.icon + '.jpg')
                    "
                />
                <div class="item-text-box">
                    <h3>{{ item.title }}</h3>
                    <p v-for="(li, t) in item.list" :key="t">
                        {{ li }}
                    </p>
                </div>
            </div>
        </div>
        <div class="list-text-box content mar-t-80">
            <h1>平台赋能合伙人成长</h1>
            <span>{{ "Platforms enable partners to grow".toUpperCase() }}</span>
        </div>
        <div class="content-p group-box">
            <img src="@/assets/img/partnerv/group1.jpg" width="1240px" />
        </div>
        <div class="content-p flex po-r">
            <img
                class="po-a growth"
                src="@/assets/img/partnerv/growth.png"
                height="190px"
            />
            <div class="group-title flex fl-d-c fl-ai-fend mar-t-223">
                <h3>“佣金秒支付”-多种金融赋能工具</h3>
                <p class="t-al-r">
                    平台联合强大的金融资源，为合伙人提供佣金秒结秒付赋能，实现合伙人销售即结算，所见即所得。
                </p>
                <img src="@/assets/img/partnerv/group2.jpg" height="330px" />
            </div>
            <div class="group-line flex fl-d-c fl-ai-c">
                <img
                    class="node1"
                    src="@/assets/img/partnerv/line-node.png"
                    width="50px"
                />
                <img
                    class="node2"
                    src="@/assets/img/partnerv/line-node.png"
                    width="50px"
                />
            </div>
            <div class="group-title mar-t-60">
                <h3>“合商学院”-合伙人培训体系</h3>
                <p>
                    平台面向不同画像的合伙人，提供系列成长课程，以线上+线下的方式，创造合伙人终身成长体系。
                </p>
                <img
                    class="mar-l-28"
                    src="@/assets/img/partnerv/group3.jpg"
                    height="330px"
                />
            </div>
        </div>
        <transition name="fade">
            <div class="qrcode-box" v-if="qrShow">
                <div class="qr-item">
                    <img
                        src="@/assets/img/qrcode-partner.png"
                        width="140"
                        height="140"
                    />
                    <p>扫码加入好生活合伙人</p>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
 
export default {
  name: 'Partnerv',
  data(){
      return{
          scrollTop:0,
          qrShow:false,
          worksList:[
              {title:'独立合伙人',icon:'list-icon1',list:[
                  '有少量周边资源或人脉',
                  '具备销售意识',
                  '有强烈赚钱欲望，想将资源变现或成立团队',
                  '有一定精力投入合伙人事业或专职房产从业人员',
                  '正在寻找赚钱/副业机会，或更多销售资源的人士',
                  ]
                },
              {title:'牵头合伙人',icon:'list-icon2',list:[
                  '有一定资源或人脉',
                  '有小规模的业务团队',
                  '具备一定的销售能力，能牵头建立团队',
                  '有强烈赚钱欲望，想将资源变现或扩大销售规模',
                  '有一定精力投入合伙人事业或专职房产从业人员',
                  ]
                },
              {title:'项目合伙人',icon:'list-icon3',list:[
                  '有较多的资源或人脉',
                  '有基本规模的业务团队',
                  '具备很强的销售能力及一定管理能力',
                  '有强烈的赚钱欲望',
                  '有精力投入合伙人事业或专职房产从业',
                  '寻找赚钱/创业机会，或更多销售资源',
                  ]
                },
              {title:'城市合伙人',icon:'list-icon4',list:[
                  '有很强的社会资源、人脉及经营管理能力',
                  '能吸纳更多团队，稳定业绩输出',
                  '开拓市场能力强，可操盘城市业务运营',
                  '较强培训与发展能力，助力平台业绩提升',
                  '积极参与平台事务，助力平台管理提升',
                  '认可并维护平台文化，传递正能量',
                  ]
                },
          ]
      }
  },
  mounted(){
        window.addEventListener('scroll', this.onScroll)
  },
  methods:{
       onScroll(){
        this.scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
         this.qrShow =(this.scrollTop >document.documentElement.clientHeight) || (this.scrollTop >document.body.clientHeight) 
        
    },
  },
  components: {
  }
}
</script>
<style lang="scss" scoped>
.Partnerv {
    .pad-t-120 {
        padding-top: 120px;
    }
    .mar-l-28 {
        margin-left: 28px;
    }
    .mar-t-223 {
        margin-top: 223px;
    }
    .mar-t-60 {
        margin-top: 60px;
    }
    .banner-box {
        background: url("~@/assets/img/partnerv/banner.jpg") no-repeat center;
        background-size: cover;
        height: 840px;
        max-width: 1920px;
        min-width: 1200px;
        margin: 0 auto;
        margin-bottom: -390px;
    }
    .title-text-box {
        padding-top: 106px;

        > h1 {
            font-size: 48px;
            font-weight: bold;
            line-height: 66px;
            letter-spacing: 2px;
        }
        > p {
            font-size: 22px;
            line-height: 43px;
            letter-spacing: 1px;

            &:first-child {
                margin-top: 11px;
            }
        }
    }
    .line-box {
        background: url("~@/assets/img/partnerv/line-list.png") no-repeat center;
        background-size: cover;
        height: 604px;
        max-width: 1920px;
        min-width: 1200px;
        margin:0 auto;
    }
    .list-text-box {
        > h1 {
            font-size: 40px;
            font-weight: bold;
            line-height: 52px;
            letter-spacing: 1px;
            margin-bottom: 4px;
        }
        > span {
            font-size: 24px;
            font-weight: bold;
            color: #dbdfe9;
            line-height: 28px;
            letter-spacing: 2px;
        }
    }
    .list-box {
        margin-top: 40px;

        > div {
            width: 590px;
            min-height: 271px;
            box-shadow: 0px 4px 24px 0px rgba(72, 152, 255, 0.16);
            border-radius: 4px;
            margin-bottom: 20px;
            align-items: flex-start;
            padding: 24px 30px;
            background: no-repeat 95% 11px #ffffff;
            background-size: auto 73px;

            &:nth-child(2n + 1) {
                margin-right: 20px;
            }
            > img {
                width: 54px;
                height: 54px;
                margin-right: 20px;
            }
            &.bg1 {
                background-image: url("~@/assets/img/partnerv/list-bg1.jpg");
            }
            &.bg2 {
                background-image: url("~@/assets/img/partnerv/list-bg2.jpg");
            }
            &.bg3 {
                background-image: url("~@/assets/img/partnerv/list-bg3.jpg");
            }
            &.bg4 {
                background-image: url("~@/assets/img/partnerv/list-bg4.jpg");
            }
        }
        .item-text-box {
            > h3 {
                font-size: 22px;
                font-weight: bold;
                line-height: 29px;
                margin-bottom: 16px;
            }
            > p {
                font-size: 18px;
                line-height: 28px;
                margin-bottom: 8px;
            }
        }
    }
    .group-box {
        padding-top: 40px;
        > img {
            margin: 0 -20px;
        }
    }
    .group-title {
        > h3 {
            font-size: 22px;
            font-weight: bold;
            line-height: 29px;
            margin-bottom: 14px;
            padding: 0 52px;
        }
        > p {
            font-size: 18px;
            line-height: 28px;
            width: 526px;
            padding: 0 52px;
            margin-bottom: 24px;
        }
    }
    .group-line {
        width: 2px;
        background: rgba(72, 152, 255, 0.2);

        > .node1 {
            margin-top: 50px;
        }
        > .node2 {
            margin-top: 117px;
        }
    }
    .growth {
        top: 70px;
        transform: translate(-286px, 0px);
    }
    .qrcode-box {
        position: fixed;
        right: 100px;
        top: 50%;
        z-index: 111;
        transform: translate(0px, -50%);
    }
    .qr-item {
        width: 160px;
        height: 182px;
        background: #ffffff;
        box-shadow: 0px 5px 19px 0px rgba(205, 216, 238, 0.4);
        border-radius: 4px;
        padding: 8px 0;
        position: relative;
        text-align: center;

        > p {
            font-size: 14px;
            color: #333333;
            line-height: 19px;
            margin-top: 4px;
        }

        &::after {
            content: "";
            position: absolute;
            top: 0;
            height: 2px;
            background: #4898ff;
            left: 0;
            right: 0;
        }
        &.item2 {
            margin-top: 25px;
            &::after {
                background: #4898ff;
            }
        }
    }
}
</style>
